@use "./_mantine.scss" as *;
.root {
    align-items: center;
    gap: var(--mantine-spacing-sm);

    flex: 0 0 auto;

    padding: var(--mantine-spacing-md);
    border-radius: var(--mantine-radius-sm);
    background-color: var(--mantine-color-gray-0);

    text-align: center;
    font-size: var(--mantine-font-size-xs);

    :global .mantine-Card-section {
        position: relative;

        align-self: stretch;
        border-color: var(--mantine-color-gray-1);

        &[data-first-section] {
            padding: 8px;

            font-size: 9px;
            font-weight: 700;
            text-transform: uppercase;
            color: var(--mantine-color-primary-light-color);

            border-bottom: 1px solid var(--mantine-color-gray-1);
        }
    }
}

.close {
    position: absolute;
    top: 15px;
    right: 6px;

    color: var(--mantine-color-gray-5);

    transform: translateY(-50%) !important;
}
