@use "./_mantine.scss" as *;
.root {
    user-select: none;

    display: flex;
    align-items: center;
    gap: var(--mantine-spacing-md);

    font-size: var(--mantine-font-size-xs);
    color: var(--mantine-color-gray-6);
}

.fullWidth {
    height: var(--header-height);
    border-bottom: 1px solid var(--mantine-color-gray-1);

    padding: var(--mantine-spacing-md);
}

.rightSection {
    margin-left: auto;
    margin-right: -8px;
}

.sticky {
    background-color: var(--mantine-color-white);
    border-left: 1px solid var(--mantine-color-gray-1);
}

.floatingWrapper {
    position: fixed;
    top: var(--mantine-spacing-xs);
    left: var(--mantine-spacing-xs);
    z-index: 100;

    transition: left 200ms ease-in-out;

    display: flex;
    justify-content: space-between;
    gap: 8px;

    &[data-sidebar-open='true'] {
        left: calc(var(--mantine-spacing-xs) + var(--sidebar-nav-width));
    }

    @media (max-width: $mantine-breakpoint-sm) {
        width: calc(100% - 2 * var(--mantine-spacing-xs)) !important;
    }
}

.floating {
    min-width: var(--header-height-floating);
    height: var(--header-height-floating);
    padding: 8px var(--mantine-spacing-sm);

    background-color: var(--mantine-color-white);

    border-radius: var(--mantine-radius-sm);
    border: 1px solid var(--mantine-color-gray-3);
}

.floatingRightSection {
    @media (max-width: $mantine-breakpoint-md) {
        display: flex;
        align-items: center;
        justify-content: center;

        width: var(--header-height-floating);
        height: var(--header-height-floating);

        background-color: var(--mantine-color-white);

        border-radius: var(--mantine-radius-sm);
        border: 1px solid var(--mantine-color-gray-3);

        .sidebarToggle {
            margin: 0 !important;
        }
    }
}

.floatingFullWidth {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;

    height: var(--header-height);
    padding: var(--mantine-spacing-md);

    background-color: rgba(0, 0, 0, 0.4);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    color: var(--mantine-color-white);

    transition: left 200ms ease-in-out;

    &[data-sidebar-open='true'] {
        left: var(--sidebar-nav-width);
    }

    .sidebarToggle {
        &:hover {
            background-color: rgba(255, 255, 255, 0.2);
        }
    }

    @media (min-width: $mantine-breakpoint-md) {
        :global .mantine-Button-root {
            &[data-variant='light'],
            &[data-variant='subtle'],
            &[data-variant='transparent'] {
                --button-color: var(--mantine-color-white) !important;
            }

            &[data-variant='filled'],
            &[data-variant='outline'] {
                --button-color: var(--mantine-color-gray-9) !important;

                border-color: var(--mantine-color-white);
                background-color: var(--mantine-color-white);
            }
        }
    }
}

.breadcrumbs {
    :global .mantine-Breadcrumbs-separator {
        color: inherit;

        svg {
            width: 10px;
            height: 10px;

            opacity: 0.6;
        }
    }
}

.sidebarToggle {
    display: flex;

    padding: 4px;
    margin: -4px;

    border-radius: var(--mantine-radius-sm);

    color: inherit;

    &:hover {
        background-color: var(--mantine-color-gray-1);
    }
}

.rightSectionDrawer {
    :global {
        .mantine-Overlay-root {
            background-color: transparent;
        }

        .mantine-Drawer-content {
            box-shadow: -20px 0 100px rgba(0, 0, 0, 0.2);
        }
    }
}

.link {
    &:hover {
        text-decoration: underline;
    }
}
