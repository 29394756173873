@use "./_mantine.scss" as *;
.button {
    position: relative;

    display: flex;
    align-items: center;

    gap: 4px;

    height: 26px;

    padding: 0 8px;

    font-size: 10px;
    font-weight: 500;

    background-color: #ffffff;

    border: 1px solid var(--mantine-color-gray-2);
    border-radius: var(--mantine-radius-xs);

    svg {
        flex-grow: 0;
        flex-shrink: 0;

        width: 10px;
        height: 10px;

        &:first-child {
            margin-left: -2px;
        }

        &:last-child {
            margin-right: -2px;
        }

        &:first-child:last-child {
            width: 12px;
            height: 12px;
        }
    }

    strong {
        font-weight: 700;
        font-style: normal;
    }

    &:hover,
    &[data-active='true'] {
        background-color: var(--mantine-color-gray-0);
    }

    &:focus {
        outline: none;
    }

    &:disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    &.dark {
        border-color: var(--mantine-color-gray-8);
        background-color: var(--mantine-color-gray-8);
        color: var(--mantine-color-gray-0);

        &:hover,
        &[data-active='true'] {
            background-color: var(--mantine-color-gray-7);
        }
    }

    &.fullWidth {
        width: 100%;
        justify-content: center;
    }

    &.loading {
        *:not(.loader) {
            opacity: 0;
        }
    }

    &[data-size='xs'] {
        height: 24px;

        &:has(*) {
            width: 24px;
            height: 24px;
        }

        svg {
            &:first-child:last-child {
                width: 16px;
                height: 16px;

                margin-left: -5px;
                margin-right: -5px;
            }
        }
    }

    &[data-size='md'] {
        width: 30px;
        height: 30px;

        svg {
            &:first-child:last-child {
                width: 16px;
                height: 16px;

                margin-left: -2px;
                margin-right: -2px;
            }
        }
    }
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.buttonGroup {
    display: flex;

    .button {
        border-radius: 0;
    }

    .button:first-child {
        border-top-left-radius: var(--mantine-radius-xs);
        border-bottom-left-radius: var(--mantine-radius-xs);
    }

    .button:last-child {
        border-top-right-radius: var(--mantine-radius-xs);
        border-bottom-right-radius: var(--mantine-radius-xs);
    }

    .button:not(:first-child) {
        margin-left: -1px; // to remove double borders
    }
}
