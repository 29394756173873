@use "./_mantine.scss" as *;
.teamSwitcher {
    --button-fz: var(--mantine-font-size-md) !important;
    --button-hover: var(--mantine-color-gray-0) !important;

    padding: 6px 6px;
    margin: -6px -6px;

    height: auto;

    :global .mantine-Button-section {
        color: var(--mantine-color-gray-6);

        &[data-position='left'] {
            margin-inline-end: 8px;
        }

        &[data-position='right'] {
            margin-inline-start: 4px;
        }
    }
}
