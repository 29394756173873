@use "./_mantine.scss" as *;
.root {
    border: 1px solid var(--mantine-color-gray-2);
    border-radius: var(--mantine-radius-xs);

    * {
        font-size: var(--mantine-font-size-md);
    }

    ul:not([data-type='taskList']) {
        margin: var(--mantine-spacing-xs);
        padding-left: var(--mantine-spacing-sm);
    }

    a {
        color: var(--mantine-color-brand-5);
    }

    &:focus-within {
        border-color: var(--mantine-color-gray-4);
    }
}

.toolbar {
    border-color: var(--mantine-color-gray-2);
}
