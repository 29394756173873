@use "./_mantine.scss" as *;
.actionsGroup {
    &::before {
        margin-bottom: 4px;
    }
}

.action {
    padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);

    &[data-selected] {
        background: var(--mantine-color-gray-1);
        color: var(--mantine-color-black);
    }

    mark {
        font-weight: 500;
        background-color: transparent;
    }
}

.actionSection {
    &[data-dimmed='true'] {
        color: currentColor !important;

        opacity: 1 !important;
    }

    &[data-position='left'] {
        margin-inline-end: var(--mantine-spacing-xs);
    }
}
