@use "./_mantine.scss" as *;
.chartWrapper {
    position: relative;
    aspect-ratio: 5/4;

    width: 100%;
}

.chart {
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;
}
