@use "./_mantine.scss" as *;
.search {
    display: flex;
    align-items: center;

    gap: 8px;

    height: 28px;

    margin-bottom: calc(var(--mantine-spacing-xs) * -1);
    padding: 4px 8px;

    font-size: var(--mantine-font-size-sm);
    font-weight: 500;

    line-height: 1;

    color: var(--mantine-color-gray-5);

    border: 1px solid var(--mantine-color-gray-2);
    border-radius: var(--mantine-radius-sm);
}

.placeholder {
    flex-grow: 1;
    flex-shrink: 1;
}

.shortcut {
    display: flex;
    align-items: center;
}
