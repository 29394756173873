/* Keep these variables in sync with diagram-dimensions.ts */

:root {
    --cell-width: 140px;
    --cell-height: 100px;
    --cell-padding: 20px;
    --cell-padding-left: calc(var(--cell-width) - var(--cell-padding) - var(--icon-width));
    --icon-width: 60px;
    --icon-height: 60px;
}

.highlighted {
    -webkit-filter: drop-shadow(0px 0px 1px);
    filter: drop-shadow(0px 0px 1px);
}
