@use "./_mantine.scss" as *;
.sidebar {
    width: var(--sidebar-nav-width);

    background-color: var(--mantine-color-white);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);

    display: flex;
    flex-direction: column;

    height: 100%;

    transition: transform 200ms ease-in-out;

    &[data-open='false'][data-floating='true'] {
        transform: translateX(-100%);
        pointer-events: none;
    }

    @media (max-width: $mantine-breakpoint-md) {
        &[data-open-mobile='true'][data-floating='true'] {
            transform: translateX(0);
            pointer-events: all;
        }
    }

    .inner {
        flex-grow: 1;

        gap: var(--mantine-spacing-xl);

        padding: var(--mantine-spacing-md);
    }
}

.scrollArea {
    :global .mantine-ScrollArea-viewport > div {
        display: flex !important;
        flex-direction: column;

        height: 100%;
    }
}
