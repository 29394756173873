@use "./_mantine.scss" as *;
.wrapper {
    width: 100%;

    padding: var(--mantine-spacing-md);

    @media (min-width: $mantine-breakpoint-sm) {
        padding: var(--mantine-spacing-xl);
    }
}
