@keyframes pulse {
    from {
        opacity: 100%;
    }
    to {
        opacity: 40%;
    }
}

.animation-pulse {
    animation: pulse 1s infinite alternate-reverse;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.animation-spin {
    animation: spin 1s linear infinite;
}

.animation-border-spin {
    --background-color: white;
    --animation-color: var(--mantine-color-gray-4);

    --border-angle: 0turn;
    --main-bg: conic-gradient(
        from var(--border-angle),
        var(--background-color),
        var(--background-color) 5%,
        var(--background-color) 60%,
        var(--background-color) 95%
    );
    --gradient-border: conic-gradient(
        from var(--border-angle),
        transparent 25%,
        var(--animation-color),
        transparent 99%,
        transparent
    );

    border: solid 2px transparent;
    background:
        var(--main-bg) padding-box,
        var(--gradient-border) border-box,
        var(--main-bg) border-box;
    animation: bg-spin 4s linear infinite;
}

@keyframes bg-spin {
    to {
        --border-angle: 1turn;
    }
}

@property --border-angle {
    syntax: '<angle>';
    inherits: true;
    initial-value: 0turn;
}
