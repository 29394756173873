@use "./_mantine.scss" as *;
.input {
    --input-size: 36px;
    --input-padding-y: 0px;
    --input-padding-x: 10px;
    --input-font-size: 12px;

    height: var(--input-size);
    min-height: var(--input-size);

    padding: var(--input-padding-y) var(--input-padding-x);

    font-size: var(--input-font-size);
    font-weight: 500;

    border: 1px solid var(--mantine-color-gray-2);
    border-radius: var(--mantine-radius-xs);

    &::placeholder {
        font-weight: 400;
    }

    &:focus,
    &:focus-within {
        border-color: var(--mantine-color-gray-4);
    }

    &[readonly] {
        cursor: default;

        &:hover {
            border-color: var(--mantine-color-gray-2);
        }
    }
}

.pillsInput {
    --input-padding-y: 4px;
    --pill-radius: var(--mantine-radius-sm);

    height: auto;

    display: flex;
    align-items: center;

    :global .mantine-PillGroup-group {
        height: 100%;
        width: 100%;
    }
}

.inputWrapper {
    input,
    select,
    textarea {
        @extend .input;
    }

    textarea {
        height: auto;
        padding: var(--mantine-spacing-xs);
    }

    :global .mantine-Input-section {
        font-size: 14px;
    }

    [data-with-left-section='true'] {
        input,
        select,
        textarea {
            padding-left: var(--input-padding-inline-start);
        }
    }

    [data-with-right-section] {
        input,
        select,
        textarea {
            padding-right: var(--input-padding-inline-end);
        }
    }

    &[data-size='xs'] {
        input,
        select {
            --input-size: 24px;
            --input-padding-y: 0px;
            --input-padding-x: 6px;
            --input-font-size: 11px;
        }

        :global .mantine-Input-section {
            font-size: 11px;
        }
    }

    &[data-size='sm'] {
        input,
        select {
            --input-size: 30px;
            --input-padding-y: 0px;
            --input-padding-x: 8px;
            --input-font-size: 12px;
        }

        :global .mantine-Input-section {
            font-size: 12px;
        }

        :global .mantine-NumberInput-controls {
            --ni-chevron-size: 10px !important;

            height: 28px !important;
        }
    }

    &[data-variant='edit-on-hover'] {
        input {
            padding: 0;

            border: 1px solid transparent;

            transition: padding 0.15s ease;

            &:hover,
            &:focus {
                padding: var(--input-padding-y) var(--input-padding-x);
            }

            &:hover {
                border-color: var(--mantine-color-gray-2);
            }

            &:focus {
                border-color: var(--mantine-color-gray-4);
            }
        }
    }

    &[data-variant='edit-on-hover-dashed'] {
        input {
            border: none;
            border-bottom: 1px dashed var(--mantine-color-gray-3);

            transition: padding 0.15s ease;

            &:hover {
                border-color: var(--mantine-color-gray-4);
            }

            &:focus {
                border-color: var(--mantine-color-gray-5);
            }
        }
    }
}

.buttonGroup[data-as-input] {
    --button-size: 36px;
    --button-font-size: 14px;

    button {
        --button-padding-y: 0px !important;
        --button-padding-x: 10px !important;

        height: var(--button-size);

        color: var(--mantine-color-black);
        font-size: var(--button-font-size);
        font-weight: 500;

        border: 1px solid var(--mantine-color-gray-2);

        &:hover,
        &:focus {
            background-color: var(--mantine-color-gray-0);
            outline: none;
        }

        &[data-active='true'] {
            background-color: var(--mantine-color-gray-2);
            font-weight: 600;
        }

        &[data-size='xs'] {
            --button-size: 22px;
            --button-font-size: 12px;
        }

        &[data-size='sm'] {
            --button-size: 30px;
            --button-font-size: 12px;
        }
    }
}

.button {
    &:focus {
        outline: none;
    }
}
