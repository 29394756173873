@use "./_mantine.scss" as *;
.item {
    user-select: none;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    padding: 4px 8px;
    margin: -4px -8px;

    border-radius: var(--mantine-radius-sm);

    color: var(--mantine-color-gray-7);
    font-size: var(--mantine-font-size-sm);
    font-weight: 500;

    &:hover,
    &[data-active='true'] {
        background-color: var(--mantine-color-gray-0);
    }

    &[data-active='true'] {
        color: var(--mantine-color-gray-9);
    }
}

.icon {
    align-self: flex-start;
    margin-top: 3px;

    display: flex;

    color: var(--mantine-color-gray-5);

    svg {
        width: 12px;
        height: 12px;
    }
}

.leftSection {
    display: flex;
    align-items: center;
    gap: 8px;

    flex-grow: 1;
}

.rightSection {
    display: flex;

    color: var(--mantine-color-gray-5);
}

.text {
    display: block;
    width: 120px;

    flex: 1 1 auto;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    [data-color] & {
        --color-1: var(--mantine-color-primary-4);
        --color-2: var(--mantine-color-brandOriginal-4);

        color: var(--color-1);

        background: linear-gradient(130deg, var(--color-1), var(--color-2));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    [data-color='gradient'] & {
        --color-1: var(--mantine-color-primary-4);
        --color-2: var(--mantine-color-brandOriginal-4);
    }

    [data-color='yellow'] & {
        --color-1: var(--mantine-color-yellow-6);
        --color-2: var(--mantine-color-red-6);
    }

    [data-color='gray'] & {
        --color-1: var(--mantine-color-gray-7);
        --color-2: var(--mantine-color-gray-8);
    }
}
