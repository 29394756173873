@use "./_mantine.scss" as *;
.root {
    height: var(--header-height);
    border-bottom: 1px solid var(--mantine-color-gray-1);

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    padding: var(--mantine-spacing-md);
}

.logo {
    display: flex;

    padding: 6px;
    margin: -6px;
    border-radius: var(--mantine-radius-sm);

    &:hover {
        background-color: var(--mantine-color-gray-0);
    }
}

.rightSide {
    display: flex;
    align-items: center;
    gap: 8px;
}
