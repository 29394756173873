@use "./_mantine.scss" as *;
.badge {
    font-size: 8px;
    padding: 0 4px;
    min-width: 16px;

    flex-shrink: 0;

    &[href] {
        cursor: pointer;
    }
}
