@use "./_mantine.scss" as *;
.finishSignup {
    margin-bottom: calc(var(--mantine-spacing-xs) * -1);
    padding: 8px;

    font-size: var(--mantine-font-size-xs);
    font-weight: 500;

    background-color: var(--mantine-color-blue-0);

    display: flex;
    flex-direction: column;
    gap: var(--mantine-spacing-xs);

    border: 1px solid var(--mantine-color-blue-1);
    border-radius: var(--mantine-radius-sm);
}
