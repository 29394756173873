@use "./_mantine.scss" as *;
.button {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 4px;
    margin: -4px;

    width: 24px;
    height: 24px;

    &:hover {
        color: var(--mantine-color-gray-7);
    }
}
