@use "./_mantine.scss" as *;
.typewriter {
    overflow: hidden;
    animation: typing 1s steps(var(--typewriter-steps, end));

    @keyframes typing {
        from {
            width: 0;
        }
        to {
            width: 100%;
        }
    }

    &[data-disabled='true'] {
        animation: none;
    }
}
