@use "./_mantine.scss" as *;
.hero {
    position: relative;

    padding: var(--mantine-spacing-md);

    padding: var(--mantine-spacing-md);
    padding-top: calc(var(--header-height) + var(--mantine-spacing-md));

    background-color: var(--mantine-color-brand-9);

    @media (min-width: $mantine-breakpoint-sm) {
        padding: var(--mantine-spacing-xl);
        padding-top: calc(var(--header-height) + var(--mantine-spacing-xl));
    }
}

.content {
    max-width: calc(var(--wide-content-width) - var(--mantine-spacing-xl) * 2);
    margin: 0 auto;
}

.actions {
    margin-top: var(--mantine-spacing-xl);

    :global .mantine-Button-section {
        color: rgba(white, 0.5);
    }

    :global .mantine-Button-root {
        background-color: rgba(white, 0.05);
        border-color: rgba(white, 0.1);

        color: rgba(white, 0.8);
        font-weight: 500;

        &:hover,
        &:focus {
            background-color: rgba(white, 0.1);

            color: white;

            .mantine-Button-section {
                color: white;
            }
        }
    }
}

.componentIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;

    height: 100%;
    padding: 8px;

    background-color: rgba(white, 0.05);
    border: 1px solid rgba(black, 0.2);
    border-radius: 1px;

    color: white;
    text-decoration: none;

    aspect-ratio: 1 / 1;

    &:hover {
        background-color: rgba(white, 0.1);

        .icon {
            color: white;
        }

        .title {
            color: white;
        }
    }

    &[data-selected='true'] {
        outline: 2px solid rgba(white, 0.25);
        outline-offset: 2px;
    }

    .icon {
        width: 30px;
        height: 30px;

        color: rgba(white, 0.8);

        transition: color 200ms;

        @media (max-width: $mantine-breakpoint-xs) {
            width: 20px;
            height: 20px;
        }
    }

    .title {
        margin-top: 2px;

        color: rgba(white, 0.5);
        font-size: var(--mantine-font-size-sm);
        text-align: center;
        line-height: 1;

        transition: color 200ms;
    }
}
