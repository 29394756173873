@use "./_mantine.scss" as *;
.root {
    min-height: 100vh;
    background-color: var(--mantine-color-gray-0);
}

.navbar {
    border: none;
    background-color: var(--mantine-color-white);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}

.main {
    display: flex;
    flex-direction: column;
}

.title {
    font-weight: 700;
    font-size: min(clamp(1.2rem, 2vw, 3rem), 30px);
    text-align: left;
    line-height: 1.2;
}
