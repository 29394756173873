@use "./_mantine.scss" as *;
.bar {
    --bar-color: var(--mantine-color-primary-3);

    position: fixed;
    left: 0;
    top: 0;

    z-index: 9999;

    width: 100vw;
    height: 2px;

    background: linear-gradient(
        90deg,
        var(--bar-color) 0%,
        var(--bar-color) 10%,
        rgba(255, 255, 255, 0) 15%,
        rgba(255, 255, 255, 0) 35%,
        var(--bar-color) 40%,
        var(--bar-color) 60%,
        rgba(255, 255, 255, 0) 65%,
        rgba(255, 255, 255, 0) 85%,
        var(--bar-color) 90%,
        var(--bar-color) 100%
    );

    background-size: 200% 100%;

    opacity: 0;
    transition: opacity 1s;

    pointer-events: none;

    background-position: 0% 0%;

    @keyframes loadingGradient {
        0% {
            background-position: 0% 0%;
        }
        100% {
            background-position: 100% 0%;
        }
    }

    &[data-loading='true'] {
        opacity: 1;
        animation: loadingGradient 2s infinite linear reverse;
    }

    &[data-touch-device='tv'] {
        height: 5px;
    }
}
