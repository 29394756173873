@use "./_mantine.scss" as *;
.card {
    position: relative;
    padding: var(--mantine-spacing-sm);
    transition:
        background-color 0.3s ease,
        border-color 0.3s ease;

    &:hover {
        background-color: var(--mantine-color-gray-0);
    }

    &[disabled] {
        opacity: 0.5;

        &:hover {
            background-color: transparent;
        }
    }

    &[data-checked] {
        border-color: var(--mantine-color-gray-5);
    }
}

.label {
    font-size: 14px;
    font-weight: 600;

    line-height: 20px;
}

.description {
    margin-top: 2px;

    font-size: 12px;

    color: var(--mantine-color-gray-6);
}
