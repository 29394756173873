@use "./_mantine.scss" as *;
.button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: var(--mantine-spacing-xs);
    border: 1px solid var(--mantine-color-gray-2);
    border-radius: var(--mantine-radius-xs);

    text-align: center;
    color: var(--mantine-color-gray-6);
    text-decoration: none;
    line-height: 1.25;

    aspect-ratio: 1 / 1;

    transition: box-shadow 200ms;

    &[href] {
        &:hover {
            box-shadow: var(--mantine-shadow-xl);
        }
    }

    &[data-selected='true'] {
        outline: 2px solid var(--mantine-color-primary-6);
        outline-offset: 1px;
    }

    b {
        font-weight: 500;
        color: var(--mantine-color-gray-9);
    }

    svg {
        margin-bottom: var(--mantine-spacing-xs);
    }

    * + * {
        margin-top: 6px;
    }
}

.label {
    flex: 0 0 auto;
    font-size: var(--mantine-font-size-xs);
}

.logo {
    flex: 1 1 auto;
    justify-content: center;

    height: 60%;

    img {
        width: 100% !important;
        max-height: 100%;
    }
}

.fallback {
    padding: var(--mantine-spacing-xs);

    background-color: var(--mantine-color-gray-0);
    border-radius: var(--mantine-radius-sm);

    color: var(--mantine-color-black);
    font-weight: 500;
}
