@use "./_mantine.scss" as *;
.dropdown {
    --menu-item-hover: var(--mantine-color-gray-0);

    width: calc(var(--sidebar-nav-width) - 16px) !important;

    border-color: var(--mantine-color-gray-1);
    box-shadow: var(--mantine-shadow-sm);
}

.itemSection {
    color: var(--mantine-color-gray-6);

    svg {
        width: 10px;
        height: 10px;
    }
}
