@use "./_mantine.scss" as *;
.users {
    display: grid;
    gap: calc(var(--mantine-spacing-xs) / 2);
}

.usersLabel {
    font-size: 12px;
    font-weight: 500;
}

.user {
    display: flex;
    align-items: center;

    gap: var(--mantine-spacing-xs);
}

.badge {
    display: flex;

    padding: 5px;

    font-size: 10px;
    font-weight: 600;

    line-height: 1;

    background-color: var(--mantine-color-gray-1);
    border-radius: var(--mantine-radius-xs);

    &[data-invited='false'] {
        color: var(--mantine-color-primary-7);

        background-color: var(--mantine-color-gray-0);
        border: 1px solid var(--mantine-color-gray-2);

        cursor: pointer;
    }

    &[data-invited='true'] {
        color: var(--mantine-color-primary-7);

        background-color: var(--mantine-color-primary-0);
        border: 1px solid var(--mantine-color-primary-1);

        cursor: pointer;
    }
}

.userActions {
    margin-left: auto;
    margin-right: 0;
}
