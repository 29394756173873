@use "./_mantine.scss" as *;
.wrapper {
    position: relative;

    display: flex;

    justify-content: center;
    align-items: center;

    width: 100%;
    max-width: 150px;

    margin-top: 4px;

    aspect-ratio: 1;

    overflow: hidden;

    background-color: var(--mantine-color-gray-1);
    border-radius: var(--mantine-radius-sm);

    border: 1px solid var(--mantine-color-gray-2);
}

.image,
.delete {
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;
}

.image {
    object-fit: contain;
}

.delete {
    display: flex;

    justify-content: center;
    align-items: center;

    transition:
        background-color 0.3s ease,
        color 0.3s ease;

    color: transparent;

    &:hover {
        background-color: rgba(0, 0, 0, 0.25);
        color: #ffffff;
    }
}
