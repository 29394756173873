@use "./_mantine.scss" as *;
.tooltip {
    background-color: var(--mantine-color-white);
    color: var(--mantine-color-gray-6);

    padding: 2px 6px;
    box-shadow: var(--mantine-shadow-xs);
    border-radius: 2px;

    font-size: var(--mantine-font-size-xs);
}
